import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useUser } from '../components/contexts/UserContext';
import { useCart } from '../components/contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StripeCheckout = ({ total }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user, token } = useUser();
  const { cart, fetchCart, removeItemFromCart } = useCart();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [shipping, setShipping] = useState({
    name: '',
    address: '',
    city: '',
    county: '',
    postcode: '',
    country: 'GB',
  });
  const [billing, setBilling] = useState({
    name: '',
    address: '',
    city: '',
    county: '',
    postcode: '',
    country: 'GB',
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      axios.get(`https://indived.co.uk/users/${user.userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        const userData = response.data;
        setEmail(userData.email || '');
        setName(userData.username || '');
        setShipping({
          name: userData.username || '',
          address: userData.shippingAddress || '',
          city: userData.shippingCity || '',
          county: userData.shippingCounty || '',
          postcode: userData.shippingPostcode || '',
          country: 'GB',
        });
        setBilling({
          name: userData.username || '',
          address: userData.billingAddress || '',
          city: userData.billingCity || '',
          county: userData.billingCounty || '',
          postcode: userData.billingPostcode || '',
          country: 'GB',
        });
      })
      .catch(error => {
        console.error('Failed to fetch user data:', error);
      });
    }
  }, [user, token]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        email,
        address: {
          line1: billing.address,
          city: billing.city,
          state: billing.county,
          postal_code: billing.postcode,
          country: billing.country,
        },
      },
    });

    if (error) {
      console.error(error);
      toast.error('Payment failed');
      return;
    }

    const { id } = paymentMethod;

    const productItems = cart.filter(item => item.productId !== null).map(item => ({
      userId: user?.userId,
      productId: item.productId,
      quantity: item.quantity,
    }));

    const treatmentItems = cart.filter(item => item.treatmentId !== null).map(item => ({
      userId: user?.userId,
      treatmentId: item.treatmentId,
      quantity: item.quantity,
    }));

    try {
      const response = await axios.post('https://indived.co.uk/api/checkout', {
        paymentMethodId: id,
        amount: total * 100,
        email,
        shipping: {
          name: shipping.name,
          address: {
            line1: shipping.address,
            city: shipping.city,
            state: shipping.county,
            postal_code: shipping.postcode,
            country: shipping.country,
          },
        },
        billing: {
          name: billing.name,
          address: {
            line1: billing.address,
            city: billing.city,
            state: billing.county,
            postal_code: billing.postcode,
            country: billing.country,
          },
        },
        userId: user?.userId,
        products: productItems,
        treatments: treatmentItems,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        console.log('Payment successful');
        toast.success('Payment successful!');

        await axios.post('https://indived.co.uk/api/send-confirmation-email', {
          email,
          orderId: response.data.orderId,
          bookingTime: response.data.bookingTime,
          total: total,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        for (const item of cart) {
          await removeItemFromCart(item.id);
        }

        await fetchCart(); // Refresh the cart

        if (treatmentItems.length > 0) {
          navigate('/booking', { state: { orderId: response.data.orderId, bookingTime: response.data.bookingTime } });
        } else {
          navigate('/orders', { state: { orderId: response.data.orderId, items: productItems, total: total } });
        }
      } else {
        console.error('Payment failed');
        toast.error('Payment failed');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      toast.error('Error processing payment');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Checkout</h2>
      
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>

      <h3 className="text-xl font-bold mb-2">Shipping Address</h3>
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          value={shipping.name}
          onChange={(e) => setShipping({ ...shipping, name: e.target.value })}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Address</label>
        <input
          type="text"
          value={shipping.address}
          onChange={(e) => setShipping({ ...shipping, address: e.target.value })}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="flex mb-4">
        <div className="w-1/3 pr-2">
          <label className="block mb-2 text-sm font-medium text-gray-700">City</label>
          <input
            type="text"
            value={shipping.city}
            onChange={(e) => setShipping({ ...shipping, city: e.target.value })}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="w-1/3 px-2">
          <label className="block mb-2 text-sm font-medium text-gray-700">County</label>
          <input
            type="text"
            value={shipping.county}
            onChange={(e) => setShipping({ ...shipping, county: e.target.value })}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="w-1/3 pl-2">
          <label className="block mb-2 text-sm font-medium text-gray-700">Postcode</label>
          <input
            type="text"
            value={shipping.postcode}
            onChange={(e) => setShipping({ ...shipping, postcode: e.target.value })}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>

      <h3 className="text-xl font-bold mb-2">Billing Address</h3>
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          value={billing.name}
          onChange={(e) => setBilling({ ...billing, name: e.target.value })}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Address</label>
        <input
          type="text"
          value={billing.address}
          onChange={(e) => setBilling({ ...billing, address: e.target.value })}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="flex mb-4">
        <div className="w-1/3 pr-2">
          <label className="block mb-2 text-sm font-medium text-gray-700">City</label>
          <input
            type="text"
            value={billing.city}
            onChange={(e) => setBilling({ ...billing, city: e.target.value })}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="w-1/3 px-2">
          <label className="block mb-2 text-sm font-medium text-gray-700">County</label>
          <input
            type="text"
            value={billing.county}
            onChange={(e) => setBilling({ ...billing, county: e.target.value })}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="w-1/3 pl-2">
          <label className="block mb-2 text-sm font-medium text-gray-700">Postcode</label>
          <input
            type="text"
            value={billing.postcode}
            onChange={(e) => setBilling({ ...billing, postcode: e.target.value })}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">Card Details</label>
        <div className="p-2 border border-gray-300 rounded">
          <CardElement />
        </div>
      </div>

      <button
        type="submit"
        disabled={!stripe}
        className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded"
      >
        Pay £{total}
      </button>
    </form>
  );
};

export default StripeCheckout;
