import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const PayPalCheckout = ({ total }) => (
  <PayPalScriptProvider options={{ "client-id": "your-client-id-here" }}>
    <div className="flex justify-center my-4">
      <PayPalButtons style={{ layout: 'vertical', color: 'blue', shape: 'rect', label: 'paypal', height: 40 }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: total // Use total prop here
              }
            }]
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(details => {
            // Handle successful payment here
            alert('Payment successful!');
          });
        }}
      />
    </div>
  </PayPalScriptProvider>
);

export default PayPalCheckout;
