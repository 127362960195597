import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios
import { fetchBlogPosts, fetchProducts } from '../api'; // Import the named functions
import VideoGallery from '../components/VideoGallery';
import ReactModal from 'react-modal';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { FaShoppingCart, FaHeart, FaTimes } from 'react-icons/fa';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

ReactModal.setAppElement('#root');

const HomePage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [products, setProducts] = useState([]);
  const [news, setNews] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  useEffect(() => {
    fetchBlogPosts()
      .then(posts => setBlogPosts(posts))
      .catch(error => console.error('Error fetching blog posts:', error));

    fetchProducts()
      .then(products => setProducts(products))
      .catch(error => console.error('Error fetching products:', error));

    fetchNews()
      .then(newsData => setNews(newsData))
      .catch(error => console.error('Error fetching news:', error));
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axios.get('/api/news');
      return response.data;
    } catch (error) {
      console.error('Error fetching news:', error);
      return [];
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!recaptchaToken) {
      alert("Please complete the CAPTCHA");
      return;
    }

    const formData = new FormData(event.target);

    alert("Form submitted successfully!");

    event.target.reset();
    setRecaptchaToken(null);
  };

  return (
    <div className="relative bg-gray-50">
      <div className="text-center relative z-10">
        <header className="bg-fixed bg-top bg-cover bg-no-repeat"
                style={{
                  backgroundImage: 'url(/Media/Images/homepagebkg.jpg)',
                  height: '75vh',
                  width: '100vw',
                }}>
          <div className="bg-black bg-opacity-50 h-full flex flex-col justify-center items-center">
            <h1 className="text-5xl font-bold text-white drop-shadow-lg">Welcome to Indived</h1>
            <p className="mt-3 text-xl text-white drop-shadow-lg">Explore the best of Ayurvedic medicine with us.</p>
          </div>
        </header>

        <section className="bg-white py-16 shadow-lg text-center">
          <img src="/Media/Images/indivedroundlogo.jpg" alt="Indived Logo" className="mx-auto h-24 w-auto" />
          <h2 className="text-4xl font-bold mt-6">The Science Of Life</h2>
          <p className="mt-4 text-lg mx-auto max-w-3xl">
            Dr Sonal Bhavsar is a qualified Ayurvedic doctor and a holistic therapist. She has been practising Ayurvedic medicine, yoga and holistic therapies since 1993. Dr Sonal runs a practising Ayurvedic clinic in Leicester and specialises in treating aches and pains, women’s problems, digestive problems, and mental illness.
          </p>
          <p className="mt-4 mx-auto max-w-3xl">
            Dr Sonal is passionate about Ayurvedic medicine and believes that everyone should learn about its curative application to today’s society. She wholeheartedly believes that everyone should learn about Ayurveda and Yoga, as a means to leading a happy and healthy life.
          </p>
          <p className="mt-4 mx-auto max-w-3xl">
            If you suffer from Rheumatism, Arthritis, Joint Pain, Sciatica, Back problems, and more, please get in touch with Dr Sonal as the Indived clinic has many remedies and curative solutions to any mental or physical illnesses.
          </p>
        </section>

        <main className="space-y-16 py-16">
          <section className="bg-white p-10 shadow-lg">
            <h2 className="text-4xl font-bold text-center mb-6">Contact Us</h2>
            <p className="text-lg text-center mb-6 mx-auto max-w-2xl">
              Ready to start your Ayurvedic journey? Contact us today to schedule a consultation or learn more about our services.
            </p>
            <div className="flex justify-center">
              <button onClick={openModal} className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300">
                Email Us
              </button>
            </div>
            <ReactModal 
              isOpen={modalIsOpen} 
              onRequestClose={closeModal} 
              className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
            >
              <div className="flex justify-end">
                <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                  <FaTimes className="w-6 h-6" />
                </button>
              </div>
              <h2 className="text-3xl font-bold mb-4 text-center">Contact Form</h2>
              <form onSubmit={handleSubmit}>
                <label className="block text-left mb-2">Name:</label>
                <input type="text" name="name" className="w-full px-4 py-2 mb-4 border rounded-md" required />
                <label className="block text-left mb-2">Email:</label>
                <input type="email" name="email" className="w-full px-4 py-2 mb-4 border rounded-md" required />
                <label className="block text-left mb-2">Message:</label>
                <textarea name="message" className="w-full px-4 py-2 mb-4 border rounded-md" required></textarea>
                <div className="flex justify-center mb-4">
                  <ReCAPTCHA
                    sitekey="6LcMTOMpAAAAAFo8iek86LqCDJM0Z2ElkJFLs9vc"
                    onChange={handleRecaptchaChange}
                  />
                </div>
                <div className="text-right">
                  <button type="submit" className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300">
                    Send
                  </button>
                </div>
              </form>
              <div className="mt-6">
                <h3 className="text-xl font-bold mb-2">Address:</h3>
                <p>68 Roman Road, Leicester, LE4 4BA</p>
                <h3 className="text-xl font-bold mb-2">Phone:</h3>
                <p>0792165385</p>
                <h3 className="text-xl font-bold mb-2">Email:</h3>
                <p>Health@indived.co.uk</p>
                <div className="mt-4">
                  <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.833434294756!2d-1.1339436844348946!3d52.64292937984148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487761b1aef3e281%3A0x5f68b72b48c56e63!2s68%20Roman%20Rd%2C%20Leicester%20LE4%204BA%2C%20UK!5e0!3m2!1sen!2sus!4v1620206820531!5m2!1sen!2sus"
                    width="100%"
                    height="250"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
            </ReactModal>
          </section>

          <VideoGallery />

          <section className="bg-white p-10 shadow-lg">
            <h2 className="text-4xl font-bold text-center mb-6">Featured Products</h2>
            <Carousel responsive={responsive}>
              {products.slice(0, 5).map(product => (
                <div
                  key={product.id}
                  className="border rounded-lg p-4 shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl cursor-pointer"
                >
                  <div className="aspect-w-1 aspect-h-1">
                    <img
                      src={`https://indived.co.uk/${product.imageUrl}`}
                      alt={product.name}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                  <h2 className="text-xl font-bold mt-4 mb-2">{product.name}</h2>
                  <p className="text-gray-700 mb-4">{product.description}</p>
                  <p className="text-lg font-semibold mb-4">£{product.price}</p>
                  <div className="flex justify-between items-center">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                      <FaShoppingCart className="mr-2" /> Add to Cart
                    </button>
                    <button
                      className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                      <FaHeart className="mr-2" /> Wishlist
                    </button>
                  </div>
                </div>
              ))}
            </Carousel>
          </section>

          <section className="bg-white p-10 shadow-lg">
            <h2 className="text-4xl font-bold text-center mb-6">Understanding Ayurveda</h2>
            <div className="text-center mb-10">
              <img src="/Media/Images/pita.jpg" alt="Ayurveda" className="mx-auto h-72 w-auto rounded-xl shadow-lg" />
            </div>
            <div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-10">
              <div className="bg-blue-100 p-6 rounded-lg shadow-md">
                <h3 className="text-3xl font-bold mb-4">Vata</h3>
                <p className="text-lg">
                  Vata is characterized by the elements air and ether. It is responsible for movement, including breathing and heartbeat. Balanced Vata promotes creativity and flexibility.
                </p>
              </div>
              <div className="bg-red-100 p-6 rounded-lg shadow-md">
                <h3 className="text-3xl font-bold mb-4">Pitta</h3>
                <p className="text-lg">
                  Pitta is composed of fire and water elements. It governs digestion, metabolism, and energy production. Balanced Pitta supports intelligence and leadership.
                </p>
              </div>
              <div className="bg-green-100 p-6 rounded-lg shadow-md">
                <h3 className="text-3xl font-bold mb-4">Kapha</h3>
                <p className="text-lg">
                  Kapha consists of water and earth elements. It provides the structure and lubrication to our bodies. Balanced Kapha brings calmness and stability.
                </p>
              </div>
            </div>
          </section>

          <section className="bg-gray-100 py-16 shadow-lg">
            <h2 className="text-4xl font-bold text-center mb-12">Our Services</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 max-w-7xl mx-auto px-8">
              <a href="/treatments" className="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl">
                <h3 className="text-3xl font-bold mb-4">Consultations</h3>
                <p className="text-lg">
                  Personalized Ayurvedic consultations to address your unique health needs.
                </p>
              </a>
              <a href="/treatments" className="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl">
                <h3 className="text-3xl font-bold mb-4">Treatments</h3>
                <p className="text-lg">
                  Holistic treatments to promote healing and balance in your body and mind.
                </p>
              </a>
              <a href="https://diabetesselfhelpgroup.co.uk/" target="_blank" rel="noopener noreferrer" className="bg-white p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl">
                <h3 className="text-3xl font-bold mb-4">Workshops</h3>
                <p className="text-lg">
                  Educational workshops to learn more about Ayurveda and its benefits.
                </p>
              </a>
            </div>
          </section>

          <section className="bg-white py-16 shadow-lg">
            <h2 className="text-4xl font-bold text-center mb-12">Latest Articles</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 max-w-7xl mx-auto px-8">
              {blogPosts.slice(0, 3).map((post, index) => (
                <a key={index} href={post.url} target="_blank" rel="noopener noreferrer" className="bg-gray-100 p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 hover:shadow-xl">
                  {post.urlToImage && (
                    <img src={post.urlToImage} alt={post.title} className="h-48 w-full object-cover rounded-t-lg mb-4" />
                  )}
                  <h3 className="text-3xl font-bold mb-4">{post.title}</h3>
                  <p className="text-lg">
                    {post.description}
                  </p>
                </a>
              ))}
            </div>
          </section>

          <section className="bg-gray-100 py-16 shadow-lg">
            <h2 className="text-4xl font-bold text-center mb-12">What Our Clients Say</h2>
            <div className="max-w-7xl mx-auto px-8">
              <Slider {...settings}>
                <div className="bg-white p-8 rounded-lg shadow-lg">
                  <p className="text-lg italic">
                    "Dr. Sonal's treatment has been life-changing. My joint pain has significantly reduced, and I feel more energetic and balanced."
                  </p>
                  <p className="mt-4 text-right font-bold">- Sarah L.</p>
                </div>
                <div className="bg-white p-8 rounded-lg shadow-lg">
                  <p className="text-lg italic">
                    "I highly recommend Indived. The holistic approach and personalized care have improved my overall well-being."
                  </p>
                  <p className="mt-4 text-right font-bold">- Michael D.</p>
                </div>
                <div className="bg-white p-8 rounded-lg shadow-lg">
                  <p className="text-lg italic">
                    "The Ayurvedic treatments have helped me manage my stress better. Thank you, Dr. Sonal!"
                  </p>
                  <p className="mt-4 text-right font-bold">- Emily R."
                  </p>
                </div>
              </Slider>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default HomePage;
