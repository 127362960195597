// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { isMobile, isTablet } from 'react-device-detect';
import Navbar from './components/Navbar';
import MobileNavbar from './components/MobileNavbar';
import Login from './components/Login';
import Register from './components/Register';
import HomePage from './Pages/Homepage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import Footer from './components/Footer';
import './i18n';
import Testimonial from './Pages/Testimonial';
import Store from './Pages/Store';
import ViewCart from './Pages/ViewCart';
import TreatmentPage from './Pages/Treatments';
import BookingPage from './components/BookingPage';
import Orders from './components/Orders';
import Profile from './components/profile';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound'; // Import NotFound component

const stripePromise = loadStripe('your-publishable-key-here');

const App = () => {
  return (
    <Router>
      {isMobile || isTablet ? <MobileNavbar /> : <Navbar />}
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/store" element={<Store />} />
        <Route path="/ViewCart" element={
          <Elements stripe={stripePromise}>
            <ViewCart />
          </Elements>
        } />
        <Route path="/treatments" element={<TreatmentPage />} />
        <Route path="/Booking" element={<BookingPage />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
