import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from './contexts/UserContext';

const EditTreatmentForm = ({ treatment, onClose, onTreatmentEdited }) => {
  const [name, setName] = useState(treatment.name);
  const [description, setDescription] = useState(treatment.description);
  const [duration, setDuration] = useState(treatment.duration);
  const [price, setPrice] = useState(treatment.price);
  const [image, setImage] = useState(null);
  const { token } = useUser();

  useEffect(() => {
    setName(treatment.name);
    setDescription(treatment.description);
    setDuration(treatment.duration);
    setPrice(treatment.price);
  }, [treatment]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('duration', duration);
    formData.append('price', price);
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await axios.put(`https://indived.co.uk/api/treatments/${treatment.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      onTreatmentEdited(response.data);
      onClose();
    } catch (error) {
      console.error('Failed to edit treatment:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-1/2">
        <h2 className="text-2xl font-bold mb-4">Edit Treatment</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border rounded w-full py-2 px-3"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border rounded w-full py-2 px-3"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Duration (minutes)</label>
            <input
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              className="border rounded w-full py-2 px-3"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Price</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="border rounded w-full py-2 px-3"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Image</label>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              className="border rounded w-full py-2 px-3"
            />
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2">
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTreatmentForm;
