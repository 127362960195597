import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { useUser } from './contexts/UserContext';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const AdminAvailabilityForm = ({ isOpen, onDismiss }) => {
  const { token } = useUser();
  const [availability, setAvailability] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newAvailability, setNewAvailability] = useState({
    day: '',
    startTime: '',
    endTime: ''
  });

  useEffect(() => {
    if (isOpen) {
      fetchAdminAvailability();
    }
  }, [isOpen]);

  const fetchAdminAvailability = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://indived.co.uk/api/admin/availability', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAvailability(response.data);
    } catch (error) {
      console.error('Failed to fetch admin availability:', error);
      toast.error('Failed to fetch admin availability');
    } finally {
      setLoading(false);
    }
  };

  const addAvailability = async () => {
    const { day, startTime, endTime } = newAvailability;
    if (!day || !startTime || !endTime) {
      toast.error('Please fill all fields');
      return;
    }
    setLoading(true);
    try {
      await axios.post('https://indived.co.uk/api/admin/availability', { day, startTime, endTime }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Availability added successfully');
      fetchAdminAvailability(); // refresh
      setNewAvailability({ day: '', startTime: '', endTime: '' });
    } catch (error) {
      console.error('Failed to add availability:', error);
      toast.error('Failed to add availability');
    } finally {
      setLoading(false);
    }
  };

  const removeAvailability = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`https://indived.co.uk/api/admin/availability/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Availability removed successfully');
      fetchAdminAvailability(); // refresh
    } catch (error) {
      console.error('Failed to remove availability:', error);
      toast.error('Failed to remove availability');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss} className="fixed inset-0 z-50 overflow-y-auto">
      <ToastContainer />
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="relative bg-white rounded-md p-6 max-w-lg w-full z-50 shadow-lg">
          <button
            onClick={onDismiss}
            className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-900"
          >
            <span className="sr-only">Close</span>
            &times;
          </button>
          <h2 className="text-2xl font-bold mb-4">Manage Availability</h2>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Day</label>
            <select
              className="block w-full p-2 border rounded"
              value={newAvailability.day}
              onChange={(e) => setNewAvailability({ ...newAvailability, day: e.target.value })}
            >
              <option value="">Select a day</option>
              {daysOfWeek.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Start Time</label>
            <input
              type="time"
              className="block w-full p-2 border rounded"
              value={newAvailability.startTime}
              onChange={(e) => setNewAvailability({ ...newAvailability, startTime: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">End Time</label>
            <input
              type="time"
              className="block w-full p-2 border rounded"
              value={newAvailability.endTime}
              onChange={(e) => setNewAvailability({ ...newAvailability, endTime: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <button
              className="px-4 py-2 bg-green-500 text-white rounded"
              onClick={addAvailability}
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Add Availability'}
            </button>
          </div>
          <h3 className="text-xl font-bold mb-2">Current Availability</h3>
          <ul className="space-y-2">
            {availability.map((avail) => (
              <li key={avail.id} className="flex justify-between items-center p-2 border-b">
                <div>
                  <p className="text-gray-700">{avail.day}</p>
                  <p className="text-gray-700">
                    {avail.startTime} - {avail.endTime}
                  </p>
                </div>
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded"
                  onClick={() => removeAvailability(avail.id)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Dialog>
  );
};

export default AdminAvailabilityForm;
