import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaChevronDown, FaChevronUp, FaSearch, FaSpinner } from 'react-icons/fa';
import AdminBookingModal from './AdminBookingModal';
import { useUser } from '../components/contexts/UserContext';

const Orders = () => {
  const { user } = useUser();
  const [orders, setOrders] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('date');
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersOpen, setOrdersOpen] = useState(true);
  const [bookingsOpen, setBookingsOpen] = useState(true);
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchOrdersAndBookings = async () => {
      try {
        const response = await axios.get('https://indived.co.uk/api/orders/orders-and-bookings', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('API response:', response.data); // Log the API response
        setOrders(response.data.orders);
        setBookings(response.data.bookings);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch orders and bookings:', error);
        setError('Failed to fetch orders and bookings');
        setLoading(false);
      }
    };

    fetchOrdersAndBookings();
  }, [token]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleSort = (field) => {
    setSort(field);
  };

  const sortedOrders = [...orders].sort((a, b) => {
    if (sort === 'date') {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
    if (sort === 'status') {
      return a.status.localeCompare(b.status);
    }
    if (sort === 'price') {
      return (b.Product ? b.Product.price : (b.Treatment ? b.Treatment.price : 0)) - 
             (a.Product ? a.Product.price : (a.Treatment ? a.Treatment.price : 0));
    }
    return 0;
  });

  const sortedBookings = [...bookings].sort((a, b) => new Date(b.date) - new Date(a.date));

  const filteredOrders = sortedOrders.filter((order) => {
    return (order.Product && order.Product.name.toLowerCase().includes(search.toLowerCase())) ||
           (order.Treatment && order.Treatment.name.toLowerCase().includes(search.toLowerCase()));
  });

  const filteredBookings = sortedBookings.filter((booking) => {
    return booking.Treatment && booking.Treatment.name.toLowerCase().includes(search.toLowerCase());
  });

  const itemsPerPage = 5;
  const paginatedOrders = filteredOrders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const paginatedBookings = filteredBookings.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const isBooked = (orderId, treatmentId) => {
    return bookings.some(booking => booking.orderId === orderId && booking.treatmentId === treatmentId);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <h1 className="text-4xl font-bold mb-8 text-center">Your Orders and Bookings</h1>
      <div className="mb-4 flex justify-between items-center">
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={handleSearch}
            className="border rounded p-2"
          />
          <FaSearch className="absolute right-2 top-3 text-gray-400" />
        </div>
        <div>
          <label className="mr-2">Filter by:</label>
          <select value={filter} onChange={handleFilter} className="border rounded p-2">
            <option value="all">All</option>
            <option value="product">Product</option>
            <option value="treatment">Treatment</option>
          </select>
        </div>
        <div>
          <label className="mr-2">Sort by:</label>
          <select value={sort} onChange={(e) => handleSort(e.target.value)} className="border rounded p-2">
            <option value="date">Date</option>
            <option value="status">Status</option>
            <option value="price">Price</option>
          </select>
        </div>
        {user && user.role === 'admin' && (
          <button
            onClick={() => setIsAdminModalOpen(true)}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Manage Bookings
          </button>
        )}
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <FaSpinner className="animate-spin text-4xl text-gray-500" />
          <p className="ml-4">Loading...</p>
        </div>
      ) : error ? (
        <div className="flex justify-center items-center h-64">
          <p className="text-red-500">{error}</p>
        </div>
      ) : (
        <>
          <div className="mb-8">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setOrdersOpen(!ordersOpen)}
            >
              <h2 className="text-3xl font-semibold mb-4">Orders</h2>
              {ordersOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {ordersOpen && (
              <>
                {paginatedOrders.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {paginatedOrders.map((order) => (
                      <div key={order.id} className="border rounded-lg shadow-md p-4 bg-white hover:shadow-lg transition-shadow duration-300">
                        <p className="text-lg font-bold mb-2">Order ID: {order.id}</p>
                        <p className="text-gray-700"><strong>Status:</strong> {order.status}</p>
                        {order.Product ? (
                          <p className="text-gray-700"><strong>Product:</strong> {order.Product.name} - £{order.Product.price}</p>
                        ) : order.Treatment ? (
                          <>
                            <p className="text-gray-700"><strong>Treatment:</strong> {order.Treatment.name} - £{order.Treatment.price}</p>
                            {!isBooked(order.id, order.Treatment.id) ? (
                              <Link to="/booking" className="text-blue-500 hover:text-blue-700">
                                Book this treatment
                              </Link>
                            ) : (
                              <span className="text-green-500">Booked</span>
                            )}
                          </>
                        ) : (
                          <p className="text-gray-700"><strong>Unknown Item</strong></p>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-center text-gray-500">No orders found.</p>
                )}
              </>
            )}
          </div>
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setBookingsOpen(!bookingsOpen)}
            >
              <h2 className="text-3xl font-semibold mb-4">Bookings</h2>
              {bookingsOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {bookingsOpen && (
              <>
                {paginatedBookings.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {paginatedBookings.map((booking) => (
                      <div key={booking.id} className="border rounded-lg shadow-md p-4 bg-white hover:shadow-lg transition-shadow duration-300">
                        <p className="text-lg font-bold mb-2">Booking ID: {booking.id}</p>
                        <p className="text-gray-700"><strong>Date:</strong> {new Date(booking.date).toLocaleDateString()}</p>
                        <p className="text-gray-700"><strong>Time:</strong> {booking.time}</p>
                        <p className="text-gray-700"><strong>Status:</strong> {booking.status}</p>
                        {booking.Treatment ? (
                          <p className="text-gray-700"><strong>Treatment:</strong> {booking.Treatment.name} - £{booking.Treatment.price}</p>
                        ) : (
                          <p className="text-gray-700"><strong>Unknown Treatment</strong></p>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <p className="text-center text-gray-500">No bookings found.</p>
                    <div className="bg-gray-200 p-4 rounded">
                      <h3 className="text-xl font-bold mb-2">Raw Bookings Data</h3>
                      <pre>{JSON.stringify(bookings, null, 2)}</pre>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="mt-8 flex justify-center">
            <button
              className={`px-4 py-2 mx-1 rounded ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className={`px-4 py-2 mx-1 rounded ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
      <AdminBookingModal isOpen={isAdminModalOpen} onClose={() => setIsAdminModalOpen(false)} token={token} />
    </div>
  );
};

export default Orders;
