// src/pages/TreatmentPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCart } from '../components/contexts/CartContext';
import { useUser } from '../components/contexts/UserContext';
import AddTreatmentForm from '../components/AddTreatmentForm';
import EditTreatmentForm from '../components/EditTreatmentForm';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEdit, FaTrash } from 'react-icons/fa';

const TreatmentPage = () => {
  const [treatments, setTreatments] = useState([]);
  const [showAddTreatmentForm, setShowAddTreatmentForm] = useState(false);
  const [showEditTreatmentForm, setShowEditTreatmentForm] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const { user, token } = useUser();
  const { addItemToCart } = useCart();
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const response = await axios.get('https://indived.co.uk/api/treatments', { headers });
        setTreatments(response.data);
      } catch (error) {
        console.error('Failed to fetch treatments:', error);
      }
    };

    fetchTreatments();
  }, [token]);

  const handleAddToCart = async (treatment) => {
    try {
      const item = { treatmentId: treatment.id, quantity: 1, treatment };
      await addItemToCart(item);
      setNotification('Treatment added to cart');
      setTimeout(() => {
        setNotification('');
      }, 3000); // Hide notification after 3 seconds
    } catch (error) {
      console.error('Failed to add treatment to cart:', error);
      setNotification('Failed to add treatment to cart');
      setTimeout(() => {
        setNotification('');
      }, 3000); // Hide notification after 3 seconds
    }
  };

  const handleTreatmentAdded = (newTreatment) => {
    setTreatments([...treatments, newTreatment]);
    setShowAddTreatmentForm(false);
  };

  const handleTreatmentEdited = (editedTreatment) => {
    setTreatments(treatments.map(t => t.id === editedTreatment.id ? editedTreatment : t));
    setShowEditTreatmentForm(false);
  };

  const handleEditTreatment = (treatment) => {
    setSelectedTreatment(treatment);
    setShowEditTreatmentForm(true);
  };

  const handleDeleteTreatment = async (treatmentId) => {
    try {
      await axios.delete(`https://indived.co.uk/api/treatments/${treatmentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTreatments(treatments.filter(t => t.id !== treatmentId));
      toast.success('Treatment deleted successfully');
    } catch (error) {
      console.error('Failed to delete treatment:', error);
      toast.error('Failed to delete treatment');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      {notification && (
        <div className="fixed top-4 left-4 bg-green-500 text-white py-2 px-4 rounded shadow-lg">
          {notification}
        </div>
      )}
      <h1 className="text-2xl font-bold mb-4">Treatments</h1>
      {user && user.role === 'admin' && (
        <button
          className="mb-4 px-4 py-2 bg-green-500 text-white rounded"
          onClick={() => setShowAddTreatmentForm(true)}
        >
          Add New Treatment
        </button>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {treatments.map((treatment) => (
          <div key={treatment.id} className="border p-4 rounded">
            {treatment.imageUrl && (
              <img src={`https://indived.co.uk/${treatment.imageUrl}`} alt={treatment.name} className="w-full h-48 object-cover mb-4 rounded-lg" />
            )}
            <h2 className="text-xl font-bold">{treatment.name}</h2>
            <p>{treatment.description}</p>
            <p>Duration: {treatment.duration} minutes</p>
            <p className="text-lg font-bold">£{treatment.price}</p>
            <div className="flex justify-between items-center mt-4">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded"
                onClick={() => handleAddToCart(treatment)}
              >
                Add to Cart
              </button>
              {user && user.role === 'admin' && (
                <div className="flex items-center space-x-2">
                  <button
                    className="px-4 py-2 bg-yellow-500 text-white rounded flex items-center"
                    onClick={() => handleEditTreatment(treatment)}
                  >
                    <FaEdit className="mr-2" /> Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded flex items-center"
                    onClick={() => handleDeleteTreatment(treatment.id)}
                  >
                    <FaTrash className="mr-2" /> Delete
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {showAddTreatmentForm && (
        <AddTreatmentForm
          onClose={() => setShowAddTreatmentForm(false)}
          onTreatmentAdded={handleTreatmentAdded}
        />
      )}
      {showEditTreatmentForm && selectedTreatment && (
        <EditTreatmentForm
          treatment={selectedTreatment}
          onClose={() => setShowEditTreatmentForm(false)}
          onTreatmentEdited={handleTreatmentEdited}
        />
      )}
    </div>
  );
};

export default TreatmentPage;
