import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

const videoList = [
  { url: '/Media/Videos/01 - VID-20200823-WA0010.mp4', title: 'Weather Changes' },
  { url: '/Media/Videos/02 - VID-20200823-WA0008.mp4', title: 'Salt' },
  { url: '/Media/Videos/03 - VID-20200823-WA0006.mp4', title: 'Cinnamon' },
  { url: '/Media/Videos/07 - VID-20200823-WA0005.mp4', title: 'Mangos' },
  { url: '/Media/Videos/05 - VID-20200823-WA0007.mp4', title: 'Fennel' },
  { url: '/Media/Videos/06 - VID-20200823-WA0004.mp4', title: 'Lemon' },
  { url: '/Media/Videos/04 - VID-20200823-WA0009.mp4', title: 'Mango pt2' },
  { url: '/Media/Videos/08 - VID-20200823-WA0002.mp4', title: 'Jivan Sanjivani' },
  { url: '/Media/Videos/09 - VID-20200823-WA0003.mp4', title: 'Yoghurt' }
];

function VideoGallery() {
    const [currentVideo, setCurrentVideo] = useState(videoList[0]);
    const [playbackRate, setPlaybackRate] = useState(1);

    const handleRateChange = (rate) => {
      setPlaybackRate(rate);
      document.querySelector('.react-player').playbackRate = rate; // Adjusting playback rate
    };

    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-5">
          <div className="flex-grow">
            <ReactPlayer
              url={currentVideo.url}
              controls
              width="100%"
              height="100%"
              playing
              playbackRate={playbackRate}
              pip={true}
              className="rounded-xl shadow-2xl overflow-hidden"
            />
            <div className="flex justify-between items-center mt-2">
      
            </div>
          </div>
          <div className="w-full lg:w-96 overflow-y-auto" style={{ maxHeight: '90vh' }}>
            {videoList.map((video, index) => (
              <div key={index} className={`cursor-pointer transition duration-500 ease-in-out transform hover:scale-105 p-4 rounded-lg mb-4 ${currentVideo.url === video.url ? 'bg-blue-600 text-white' : 'bg-white hover:bg-blue-500 hover:text-white shadow-md'}`} onClick={() => setCurrentVideo(video)}>
                <h4 className="text-lg font-bold">{video.title}</h4>
                <p className="text-sm">{video.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}

export default VideoGallery;
