// src/api.js
import axios from 'axios';
import axiosRetry from 'axios-retry';

// Create an axios instance with a base URL from environment variables
const api = axios.create({
  baseURL: 'https://indived.co.uk/', // Use your Plesk server's base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Configure axios-retry
axiosRetry(api, {
  retries: 3,
  retryCondition: (error) => {
    return error.response && error.response.status === 429;
  }, // Retry only on rate limit errors
  retryDelay: (retryCount) => {
    console.warn(`Retrying request... Attempt #${retryCount}`);
    return axiosRetry.exponentialDelay(retryCount);
  },
});

// Response interceptor for logging and additional handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 429) {
      console.error('Rate limit exceeded. Retrying...');
    } else {
      console.error('Request failed:', error);
    }
    return Promise.reject(error);
  }
);

// Example API functions
export const getUserProfile = (userId) => api.get(`/users/${userId}`);
export const updateUserProfile = (userId, data) => api.put(`/users/${userId}`, data);
export const createStripeCustomer = (userId) => api.post(`/users/${userId}/create-stripe-customer`);

export const fetchProducts = async () => {
  try {
    const response = await api.get('/products');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch products:', error);
    throw error;
  }
};

export const fetchTreatments = async () => {
  try {
    const response = await api.get('/api/treatments');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch treatments:', error);
    throw error;
  }
};

export const fetchBlogPosts = async () => {
  try {
    const response = await axios.get('https://newsapi.org/v2/everything?q=ayurveda+ayurvedic&apiKey=aad579573dbb44b980a1a74673e300ca');
    return response.data.articles;
  } catch (error) {
    console.error('Failed to fetch blog posts:', error);
    throw error;
  }
};

export default api;
