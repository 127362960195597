import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../components/contexts/UserContext';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminAvailabilityForm from './AdminAvailability';

const BookingPage = () => {
  const { user, token } = useUser();
  const [purchasedTreatments, setPurchasedTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [treatmentDuration, setTreatmentDuration] = useState(0);
  const [date, setDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [availability, setAvailability] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [status, setStatus] = useState('pending');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);

  useEffect(() => {
    const fetchPurchasedTreatments = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://indived.co.uk/api/orders/orders-and-bookings', {
          headers: { Authorization: `Bearer ${token}` }
        });
        const treatments = response.data.orders
          .filter(order => order.treatmentId !== null)
          .map(order => ({
            orderId: order.id,
            treatmentId: order.treatmentId,
            name: order.Treatment.name,
            duration: order.Treatment.duration
          }));
        setPurchasedTreatments(treatments);
      } catch (error) {
        console.error('Failed to fetch purchased treatments:', error);
        setError('Failed to fetch purchased treatments');
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedTreatments();
  }, [token]);

  useEffect(() => {
    if (selectedTreatment) {
      const fetchAvailableSlots = async () => {
        try {
          const response = await axios.get('https://indived.co.uk/api/admin/availability', {
            headers: { Authorization: `Bearer ${token}` }
          });
          setAvailableSlots(response.data);
        } catch (error) {
          console.error('Failed to fetch available slots:', error);
          setError('Failed to fetch available slots');
        }
      };

      fetchAvailableSlots();
    }
  }, [selectedTreatment, token]);

  useEffect(() => {
    if (availableSlots.length > 0 && treatmentDuration > 0) {
      generateTimeSlots();
    }
  }, [availableSlots, treatmentDuration]);

  const generateTimeSlots = () => {
    const selectedDay = date.toLocaleDateString('en-US', { weekday: 'long' });
    console.log("Selected Day:", selectedDay);
    console.log("Available Slots:", availableSlots);

    const slots = availableSlots
      .filter(slot => slot.day === selectedDay)
      .map(slot => {
        const startTime = new Date(`1970-01-01T${slot.startTime}`);
        const endTime = new Date(`1970-01-01T${slot.endTime}`);
        const timeSlots = [];
        let currentTime = startTime;

        while (currentTime < endTime) {
          const nextTime = new Date(currentTime.getTime() + treatmentDuration * 60000);
          if (nextTime > endTime) break;
          timeSlots.push({
            startTime: currentTime.toTimeString().substring(0, 5),
            endTime: nextTime.toTimeString().substring(0, 5),
          });
          currentTime = nextTime;
        }

        return timeSlots;
      })
      .flat();

    console.log("Generated Time Slots:", slots);
    setTimeSlots(slots);
  };

  const handleOrderFetch = async () => {
    try {
      const response = await axios.get('https://indived.co.uk/api/orders/orders-and-bookings', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Orders fetched:', response.data.orders);
      return response.data.orders.filter(order => order.treatmentId !== null);
    } catch (error) {
      console.error('Failed to fetch orders and bookings:', error);
      toast.error('Failed to fetch orders and bookings');
      return [];
    }
  };

  const handleBookingSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
        const orders = await handleOrderFetch();

        console.log('Filtered orders:', orders);
        console.log('Selected Treatment ID:', selectedTreatment);

        const [orderId, treatmentId] = selectedTreatment.split('-').map(Number);

        const order = orders.find(order => order.id === orderId && order.treatmentId === treatmentId);

        console.log('Matching Order:', order);

        if (!order) {
            setError('No valid order found for the selected treatment.');
            toast.error('No valid order found for the selected treatment.');
            setLoading(false);
            return;
        }

        const selectedSlot = timeSlots.find(slot => slot.startTime === selectedTimeSlot);

        if (!selectedSlot) {
            setError('Selected time slot is not available.');
            toast.error('Selected time slot is not available.');
            setLoading(false);
            return;
        }

        const availabilitySlot = availableSlots.find(slot => {
            const slotStart = new Date(`1970-01-01T${slot.startTime}`);
            const slotEnd = new Date(`1970-01-01T${slot.endTime}`);
            const selectedSlotTime = new Date(`1970-01-01T${selectedSlot.startTime}`);
            return slotStart <= selectedSlotTime && slotEnd >= selectedSlotTime;
        });

        if (!availabilitySlot) {
            setError('No availability slot found for the selected time.');
            toast.error('No availability slot found for the selected time.');
            setLoading(false);
            return;
        }

        console.log('Booking Data:', {
            userId: user.userId,
            treatmentId,
            date,
            time: selectedSlot.startTime,
            status,
            orderId: order.id,
            availabilityId: availabilitySlot.id
        });

        const response = await axios.post('https://indived.co.uk/api/booking', {
            userId: user.userId,
            treatmentId,
            date,
            time: selectedSlot.startTime,
            status,
            orderId: order.id,
            availabilityId: availabilitySlot.id
        }, {
            headers: { Authorization: `Bearer ${token}` }
        });

        console.log('Booking Response:', response.data);

        toast.success('Booking successful!');
        setDate(new Date());
        setSelectedTimeSlot('');
        setSelectedTreatment('');
        setCurrentStep(1);
        setError('');
    } catch (error) {
        console.error('Failed to create booking:', error);
        setError('Failed to create booking');
        toast.error('Failed to create booking');
    } finally {
        setLoading(false);
    }
  };

  const handleNextStep = () => {
    if (currentStep === 1 && !selectedTreatment) {
      setError('Please select a treatment');
      return;
    }
    if (currentStep === 2 && (!date || !selectedTimeSlot)) {
      setError('Please select a date and time');
      return;
    }
    setError('');
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setError('');
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const openAdminModal = () => {
    setIsAdminModalOpen(true);
  };

  const handleTreatmentChange = (e) => {
    const [orderId, treatmentId] = e.target.value.split('-').map(Number);
    const treatment = purchasedTreatments.find(t => t.orderId === orderId && t.treatmentId === treatmentId);
    setSelectedTreatment(e.target.value);
    setTreatmentDuration(treatment ? treatment.duration : 0);
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      <div className="max-w-2xl mx-auto bg-white p-8 rounded shadow">
        <h1 className="text-3xl font-bold mb-4">Book a Treatment</h1>
        <form onSubmit={handleBookingSubmit}>
          {currentStep === 1 && (
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Select Treatment</label>
              <select
                className="block w-full p-2 border rounded"
                value={selectedTreatment}
                onChange={handleTreatmentChange}
              >
                <option value="">Select a treatment</option>
                {purchasedTreatments.map((treatment) => (
                  <option key={`${treatment.orderId}-${treatment.treatmentId}`} value={`${treatment.orderId}-${treatment.treatmentId}`}>
                    {treatment.name} (Order ID: {treatment.orderId})
                  </option>
                ))}
              </select>
            </div>
          )}
          {currentStep === 2 && (
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Select Date</label>
              <Calendar date={date} onChange={(date) => setDate(date)} />
              <label className="block text-gray-700 font-bold mt-4 mb-2">Select Time</label>
              <select
                className="block w-full p-2 border rounded"
                value={selectedTimeSlot}
                onChange={(e) => setSelectedTimeSlot(e.target.value)}
              >
                <option value="">Select a time slot</option>
                {timeSlots.map((slot, index) => (
                  <option key={index} value={slot.startTime}>
                    {slot.startTime} - {slot.endTime}
                  </option>
                ))}
              </select>
            </div>
          )}
          {currentStep === 3 && (
            <div className="mb-4">
              <p className="text-gray-700 font-bold mb-2">Confirm Booking</p>
              <p>Treatment: {purchasedTreatments.find((t) => t.treatmentId === Number(selectedTreatment.split('-')[1]))?.name}</p>
              <p>Date: {date.toDateString()}</p>
              <p>Time: {selectedTimeSlot}</p>
              {availability !== null && (
                <p className={`mt-2 ${availability ? 'text-green-500' : 'text-red-500'}`}>
                  {availability ? 'Time slot is available' : 'Time slot is not available'}
                </p>
              )}
            </div>
          )}
          <div className="flex justify-between mt-4">
            {currentStep > 1 && (
              <button
                type="button"
                className="px-4 py-2 bg-gray-500 text-white rounded"
                onClick={handlePreviousStep}
              >
                Previous
              </button>
            )}
            {currentStep < 3 && (
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded"
                onClick={handleNextStep}
              >
                Next
              </button>
            )}
            {currentStep === 3 && (
              <button
                type="submit"
                className="px-4 py-2 bg-green-500 text-white rounded"
                disabled={loading}
              >
                {loading ? 'Booking...' : 'Book Now'}
              </button>
            )}
          </div>
        </form>
        {user && user.role === 'admin' && (
          <div className="mt-8">
            <button
              className="px-4 py-2 bg-purple-500 text-white rounded"
              onClick={openAdminModal}
            >
              Manage Availability
            </button>
          </div>
        )}
      </div>
      <AdminAvailabilityForm isOpen={isAdminModalOpen} onDismiss={() => setIsAdminModalOpen(false)} />
    </div>
  );
};

export default BookingPage;
