import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-100 p-6">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-3 gap-4 items-center">
        {/* Contact Information */}
        <div className="text-center sm:text-left">
          <h3 className="text-lg font-semibold">Contact Us</h3>
          <p><a href="tel:+07921565385" className="hover:text-blue-300">07921565385</a></p>
          <p><a href="mailto:Indivedayu@gmail.com" className="hover:text-blue-300">Indivedayu@gmail.com</a></p>
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center gap-4">
          <a href="https://www.facebook.com/Indived" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600">
            <FaFacebookF />
          </a>
          <a href="https://twitter.com/bhavsarsonal" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com/indivedayurvedicholistic/" target="_blank" rel="noopener noreferrer" className="hover:text-pink-600">
            <FaInstagram />
          </a>
        </div>

        {/* Google Translate Element */}
        <div className="text-center sm:text-right">
          <div id="google_translate_element"></div>
        </div>
      </div>

      {/* Copyright Notice */}
      <div className="text-center text-gray-400 text-sm mt-4">
        Copyrights © 2024 by Indived. Proudly created by Quantaum.
      </div>
    </footer>
  );
}

export default Footer;
