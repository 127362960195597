import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaPlay } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import Modal from 'react-modal';
import { useUser } from '../components/contexts/UserContext'; // Import the useUser hook

Modal.setAppElement('#root');

const TestimonialPage = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const { user } = useUser(); // Get the user from context

    useEffect(() => {
        axios.get('https://indived.co.uk/videos')
            .then(response => {
                setVideos(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching videos:', error);
                setLoading(false);
            });
    }, []);

    const handleCommentSubmit = (event, videoId) => {
        event.preventDefault();
        const comment = event.target.elements.comment.value;
        axios.post('https://indived.co.uk/comments', { videoId, comment })
            .then(response => {
                alert('Comment added!');
                event.target.elements.comment.value = ''; // Reset input after submit
            })
            .catch(error => console.error('Error posting comment:', error));
    };

    const handleFileUpload = (event) => {
        event.preventDefault();
        const file = event.target.elements.file.files[0];
        const title = event.target.elements.title.value;
        const description = event.target.elements.description.value;
        const errors = validateForm(file, title, description);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        const formData = new FormData();
        formData.append('video', file);
        formData.append('title', title);
        formData.append('description', description);

        axios.post('https://indived.co.uk/videos/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            alert('Video uploaded successfully!');
            setVideos(prev => [...prev, response.data]);
        })
        .catch(error => {
            console.error('Error uploading video:', error.response ? error.response.data : error.message);
            alert(`Error uploading video: ${error.response ? error.response.data.error : error.message}`);
        });
    };

    const validateForm = (file, title, description) => {
        const errors = {};
        if (!file) errors.file = "Video file is required";
        if (!title) errors.title = "Title is required";
        if (!description) errors.description = "Description is required";
        return errors;
    };

    const openModal = (video) => {
        setCurrentVideo(video);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setCurrentVideo(null);
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-center mb-8">Video Testimonials</h1>
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {videos.map(video => (
                        <div key={video.id} className="relative p-4 bg-white shadow-lg rounded-lg hover:bg-gray-100 transition duration-300 cursor-pointer" onClick={() => openModal(video)}>
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 hover:opacity-100 transition duration-300">
                                <FaPlay className="text-white text-3xl" />
                            </div>
                            <video className="w-full mb-4 rounded-lg" controls>
                                <source src={`https://indived.co.uk/uploads/videos/${video.url}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <h3 className="text-xl font-semibold mb-2">{video.title}</h3>
                            <p className="text-gray-600 mb-4">{video.description}</p>
                            {user && user.isLoggedIn && (
                                <form onSubmit={(e) => handleCommentSubmit(e, video.id)} className="mt-4">
                                    <input name="comment" type="text" placeholder="Leave a comment..." className="w-full p-2 border rounded mb-2" />
                                    <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300">Submit</button>
                                </form>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {user && user.role === 'admin' && (
                <div className="mt-8">
                    <h2 className="text-2xl font-bold mb-4">Upload a New Video</h2>
                    <form onSubmit={handleFileUpload} className="bg-white shadow-lg rounded-lg p-8">
                        <div className="mb-4">
                            <input type="file" name="file" accept="video/*" className={`w-full p-2 border rounded ${formErrors.file ? 'border-red-500' : ''}`} required />
                            {formErrors.file && <p className="text-red-500 text-sm">{formErrors.file}</p>}
                        </div>
                        <div className="mb-4">
                            <input type="text" name="title" placeholder="Title" className={`w-full p-2 border rounded ${formErrors.title ? 'border-red-500' : ''}`} required />
                            {formErrors.title && <p className="text-red-500 text-sm">{formErrors.title}</p>}
                        </div>
                        <div className="mb-4">
                            <input type="text" name="description" placeholder="Description" className={`w-full p-2 border rounded ${formErrors.description ? 'border-red-500' : ''}`} />
                            {formErrors.description && <p className="text-red-500 text-sm">{formErrors.description}</p>}
                        </div>
                        <button type="submit" className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300">
                            Upload Video
                        </button>
                    </form>
                </div>
            )}

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Video Modal" className="flex items-center justify-center">
                {currentVideo && (
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <h2 className="text-2xl font-bold mb-4">{currentVideo.title}</h2>
                        <video className="w-full rounded-lg" controls>
                            <source src={`https://indived.co.uk/uploads/videos/${currentVideo.url}`} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <button onClick={closeModal} className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Close
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default TestimonialPage;
