import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import StripeCheckout from '../components/StripeCheckout';
import PayPalCheckout from '../components/PayPalCheckout';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCart } from '../../src/components/contexts/CartContext';  // Import useCart context

const ViewCart = () => {
  const { cart, fetchCart, removeItemFromCart, addItemToCart, clearCart } = useCart();  // Use useCart context
  const [cartDetails, setCartDetails] = useState([]);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  useEffect(() => {
    const fetchDetails = async () => {
      const details = await Promise.all(
        cart.map(async (item) => {
          try {
            if (item.productId) {
              const response = await axios.get(`https://indived.co.uk/products/${item.productId}`, {
                headers: { Authorization: `Bearer ${token}` }
              });
              return { ...item, product: response.data };
            } else if (item.treatmentId) {
              const response = await axios.get(`https://indived.co.uk/treatments/${item.treatmentId}`, {
                headers: { Authorization: `Bearer ${token}` }
              });
              return { ...item, treatment: response.data };
            }
          } catch (error) {
            console.error('Failed to fetch item details:', error);
            return { ...item, error: 'Failed to fetch details' };
          }
          return item;
        })
      );
      setCartDetails(details);
    };

    if (cart.length > 0) {
      fetchDetails();
    } else {
      setCartDetails([]);
    }
  }, [cart, token]);

  const handleRemoveItem = async (item) => {
    try {
      await removeItemFromCart(item.id);
      toast.success('Item removed from cart!');
    } catch (error) {
      console.error('Failed to remove item from cart:', error);
      toast.error('Failed to remove item from cart.');
    }
  };

  const handleIncreaseQuantity = async (item) => {
    try {
      await addItemToCart({
        productId: item.productId,
        treatmentId: item.treatmentId,
        quantity: 1,
      });
      toast.success('Item quantity increased!');
    } catch (error) {
      console.error('Failed to increase quantity:', error);
      toast.error('Failed to increase quantity.');
    }
  };

  const handleDecreaseQuantity = async (item) => {
    if (item.quantity > 1) {
      try {
        await addItemToCart({
          productId: item.productId,
          treatmentId: item.treatmentId,
          quantity: -1,
        });
        toast.success('Item quantity decreased!');
      } catch (error) {
        console.error('Failed to decrease quantity:', error);
        toast.error('Failed to decrease quantity.');
      }
    } else {
      handleRemoveItem(item);
    }
  };

  const totalPrice = cartDetails.reduce((sum, item) => {
    const price = item.product ? item.product.price : item.treatment.price;
    return sum + parseFloat(price) * item.quantity;
  }, 0).toFixed(2);

  const handlePaymentSuccess = () => {
    toast.success('Payment successful!');
    clearCart();
    navigate('/bookings');
  };

  const getImageUrl = (item) => {
    const imageUrl = item.product?.imageUrl || item.treatment?.imageUrl;
    // Check if imageUrl already includes the '/uploads/' part to avoid duplication
    return imageUrl.startsWith('uploads/') ? `https://indived.co.uk/${imageUrl}` : `https://indived.co.uk/uploads/${imageUrl}`;
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <h1 className="text-4xl font-bold mb-8 text-center">Your Cart</h1>

      {cartDetails.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item</th>
                <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {cartDetails.map((item) => (
                <tr key={item.id}>
                  <td className="py-4 px-6">
                    <Link to={`/${item.product ? 'product' : 'treatment'}/${item.product?.id || item.treatment?.id}`} className="flex items-center">
                      <img src={getImageUrl(item)} alt={item.product?.name || item.treatment?.name} className="w-20 h-20 object-cover rounded mr-4" />
                      <span className="font-medium text-gray-900">{item.product?.name || item.treatment?.name}</span>
                    </Link>
                  </td>
                  <td className="py-4 px-6 text-gray-700">£{item.product?.price || item.treatment?.price}</td>
                  <td className="py-4 px-6 text-gray-700">
                    <div className="flex items-center">
                      <button onClick={() => handleDecreaseQuantity(item)} className="px-2 py-1 text-gray-800 bg-gray-200 hover:bg-gray-300 rounded">-</button>
                      <span className="mx-2">{item.quantity}</span>
                      <button onClick={() => handleIncreaseQuantity(item)} className="px-2 py-1 text-gray-800 bg-gray-200 hover:bg-gray-300 rounded">+</button>
                    </div>
                  </td>
                  <td className="py-4 px-6 text-gray-700">£{((item.product?.price || item.treatment?.price) * item.quantity).toFixed(2)}</td>
                  <td className="py-4 px-6">
                    <button onClick={() => handleRemoveItem(item)} className="px-4 py-2 text-red-500 bg-red-100 hover:bg-red-200 rounded">Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-8 text-right">
            <h2 className="text-2xl font-bold">Total: £{totalPrice}</h2>

            <div className="flex justify-end space-x-4 mt-4">
              <StripeCheckout total={totalPrice} onPaymentSuccess={handlePaymentSuccess} />
              <PayPalCheckout total={totalPrice} />
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <p className="text-lg">Your cart is empty.</p>
          <Link to="/store" className="text-blue-500 hover:text-blue-700 mt-4 inline-block">
            Continue shopping
          </Link>
        </div>
      )}
    </div>
  );
};

export default ViewCart;
