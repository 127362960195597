import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminBookingModal = ({ isOpen, onClose, token }) => {
  const [pendingBookings, setPendingBookings] = useState([]);
  const [approvedBookings, setApprovedBookings] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const fetchBookings = async () => {
        try {
          const response = await axios.get('https://indived.co.uk/api/booking', {
            headers: { Authorization: `Bearer ${token}` }
          });
          const pending = response.data.filter(booking => booking.status === 'pending');
          const approved = response.data.filter(booking => booking.status === 'approved');
          setPendingBookings(pending);
          setApprovedBookings(approved);
        } catch (error) {
          console.error('Failed to fetch bookings:', error);
        }
      };

      fetchBookings();
    }
  }, [isOpen, token]);

  const handleApproval = async (bookingId, status) => {
    try {
      await axios.patch(`https://indived.co.uk/api/booking/${bookingId}`, { status }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (status === 'approved') {
        setPendingBookings(pendingBookings.filter(booking => booking.id !== bookingId));
        setApprovedBookings([...approvedBookings, { ...pendingBookings.find(booking => booking.id === bookingId), status }]);
      } else if (status === 'rejected') {
        setPendingBookings(pendingBookings.filter(booking => booking.id !== bookingId));
        await axios.delete(`https://indived.co.uk/api/booking/${bookingId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }
      toast.success(`Booking ${status}`);
    } catch (error) {
      console.error(`Failed to ${status} booking:`, error);
      toast.error(`Failed to ${status} booking`);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-4 rounded shadow-md w-3/4 relative">
        <h2 className="text-2xl font-bold mb-4">Manage Bookings</h2>
        <button onClick={onClose} className="absolute top-2 right-2 text-xl font-bold">&times;</button>
        <div className="overflow-y-auto max-h-96">
          <h3 className="text-xl font-semibold mb-2">Pending Bookings</h3>
          {pendingBookings.length > 0 ? (
            pendingBookings.map((booking) => (
              <div key={booking.id} className="border rounded p-4 mb-2">
                <p><strong>Booking ID:</strong> {booking.id}</p>
                <p><strong>Treatment:</strong> {booking.Treatment.name}</p>
                <p><strong>Date:</strong> {new Date(booking.date).toLocaleDateString()}</p>
                <p><strong>Time:</strong> {booking.time}</p>
                <div className="mt-2">
                  <button
                    onClick={() => handleApproval(booking.id, 'approved')}
                    className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleApproval(booking.id, 'rejected')}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Reject
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No pending bookings found.</p>
          )}
          <h3 className="text-xl font-semibold mt-4 mb-2">Approved Bookings</h3>
          {approvedBookings.length > 0 ? (
            approvedBookings.map((booking) => (
              <div key={booking.id} className="border rounded p-4 mb-2">
                <p><strong>Booking ID:</strong> {booking.id}</p>
                <p><strong>Treatment:</strong> {booking.Treatment.name}</p>
                <p><strong>Date:</strong> {new Date(booking.date).toLocaleDateString()}</p>
                <p><strong>Time:</strong> {booking.time}</p>
              </div>
            ))
          ) : (
            <p>No approved bookings found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminBookingModal;
