import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { FiSun, FiMoon } from 'react-icons/fi';
import { useUser } from './contexts/UserContext';
import { useCart } from './contexts/CartContext';

export default function Navbar() {
  const { user, logout } = useUser();
  const { cart, fetchCart, addItemToCart, removeItemFromCart, clearCart } = useCart();
  const cartItemCount = cart.reduce((count, item) => count + item.quantity, 0);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isCartDropdownOpen, setIsCartDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
  const toggleProfileMenu = () => setIsProfileOpen(!isProfileOpen);

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  const navigation = [
    { name: "Shop", href: "/store" },
    { name: "Treatments", href: "/treatments" },
    { name: "Testimonial", href: "/testimonial" },
    { name: "Forever Living", href: "https://shop.foreverliving.com/retail/entry/Shop.do?store=GBR&language=en&distribID=440000326884" },
  ];

  const getItemImageUrl = (item) => {
    if (item.Product && item.Product.imageUrl) {
      return `https://indived.co.uk/${item.Product.imageUrl}`;
    } else if (item.Treatment && item.Treatment.imageUrl) {
      return `https://indived.co.uk/${item.Treatment.imageUrl}`;
    } else {
      return '/path/to/default-image.jpg'; // Fallback image
    }
  };

  const handleAddItemToCart = async (item) => {
    await addItemToCart(item);
    await fetchCart(); // Re-fetch cart items to update the navbar
  };

  const handleRemoveItemFromCart = async (item) => {
    await removeItemFromCart(item.id);
    await fetchCart(); // Re-fetch cart items to update the navbar
  };

  const handleClearCart = async () => {
    await clearCart();
    await fetchCart(); // Re-fetch cart items to update the navbar
  };

  return (
    <nav className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} text-gray-800 flex items-center relative`}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-30">
          <Link to="/" className="flex-shrink-0">
            <img src="/Logo.jpg" alt="INDIVED Logo" className="h-28 w-auto" />
          </Link>

          <div className="hidden md:flex items-center space-x-4">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`px-3 py-2 rounded-md text-sm font-medium ${item.current ? 'bg-gray-900 text-white' : 'text-gray-700 hover:bg-gray-700 hover:text-white'}`}
              >
                {item.name}
              </Link>
            ))}
          </div>

          <div className="flex items-center space-x-4">
            <button onClick={toggleDarkMode} className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none">
              {isDarkMode ? <FiSun className="h-6 w-6" /> : <FiMoon className="h-6 w-6" />}
            </button>
            {user ? (
              <div className="relative">
                <button onClick={toggleProfileMenu} className="text-gray-800 hover:text-white focus:outline-none">
                  <span className="sr-only">Open user menu</span>
                  <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100 text-center font-medium text-gray-800">{user.username[0]}</span>
                </button>
                <Transition
                  show={isProfileOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile</Link>
                    <Link to="/orders" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Orders</Link>
                    <button onClick={logout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</button>
                  </div>
                </Transition>
              </div>
            ) : (
              <div className="flex space-x-4">
                <Link to="/login" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Login</Link>
                <Link to="/register" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Register</Link>
              </div>
            )}
            <div
              className="relative"
              onMouseEnter={() => setIsCartDropdownOpen(true)}
              onMouseLeave={() => setIsCartDropdownOpen(false)}
            >
              <Link to="/ViewCart" className="text-gray-800 hover:text-white focus:outline-none">
                <span className="sr-only">View Cart</span>
                <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100 text-center font-medium text-gray-800">
                  🛒 {cartItemCount}
                </span>
              </Link>
              <Transition
                show={isCartDropdownOpen}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {cart.length > 0 ? (
                    cart.map(item => (
                      <div key={item.id} className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-between">
                        <img src={getItemImageUrl(item)} alt={item.Product?.name || item.Treatment?.name} className="h-10 w-10 object-cover rounded-full mr-4" onError={(e) => { e.target.onerror = null; e.target.src="/path/to/default-image.jpg"; }} />
                        <span>{item.Product?.name || item.Treatment?.name}</span>
                        <div className="flex items-center">
                          <button onClick={() => handleRemoveItemFromCart(item)} className="px-2 py-1 text-gray-800 hover:bg-gray-300 rounded">-</button>
                          <span className="px-2">{item.quantity}</span>
                          <button onClick={() => handleAddItemToCart(item)} className="px-2 py-1 text-gray-800 hover:bg-gray-300 rounded">+</button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="px-4 py-2 text-sm text-gray-700">Your cart is empty</div>
                  )}
                  <Link to="/ViewCart" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    View Cart
                  </Link>
                  <button onClick={handleClearCart} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Clear Cart
                  </button>
                </div>
              </Transition>
            </div>
          </div>

          <div className="-mr-2 flex md:hidden">
            <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none">
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      <Transition show={isMobileMenuOpen} enter="transition ease-out duration-100 transform" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="transition ease-in duration-75 transform" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
        <div className="md:hidden" id="mobile-menu">
          {navigation.map((item) => (
            <Link key={item.name} to={item.href} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white">
              {item.name}
            </Link>
          ))}
        </div>
      </Transition>
    </nav>
  );
}
