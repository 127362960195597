import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useUser } from './contexts/UserContext';
import { useCart } from './contexts/CartContext';

export default function MobileNavbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, logout } = useUser();
  const { cart } = useCart();
  const cartItemCount = cart.reduce((count, item) => count + item.quantity, 0);

  const navigation = [
    { name: "Shop", href: "/store" },
    { name: "Treatments", href: "/treatments" },
    { name: "Testimonial", href: "/testimonial" },
    { name: "Forever Living", href: "https://shop.foreverliving.com/retail/entry/Shop.do?store=GBR&language=en&distribID=440000326884" },
  ];

  return (
    <nav className="bg-white text-gray-800 flex items-center justify-between p-4">
      <Link to="/" className="text-lg font-bold">
        <img src="/Logo.jpg" alt="INDIVED Logo" className="h-8 w-auto" />
      </Link>
      <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="text-gray-800 focus:outline-none">
        {isMobileMenuOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
      </button>
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Menu</h3>
                <button onClick={() => setIsMobileMenuOpen(false)} className="text-gray-800 focus:outline-none">
                  <XIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="mt-5">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white"
                    onClick={() => setIsMobileMenuOpen(false)} // Close menu on item click
                  >
                    {item.name}
                  </Link>
                ))}
                {user ? (
                  <div>
                    <Link to="/profile" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>Profile</Link>
                    <Link to="/orders" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>Orders</Link>
                    <button onClick={logout} className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white">Logout</button>
                  </div>
                ) : (
                  <div>
                    <Link to="/login" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>Login</Link>
                    <Link to="/register" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>Register</Link>
                  </div>
                )}
                <Link to="/ViewCart" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>
                  🛒 {cartItemCount}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
